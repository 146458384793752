@import "../styles/fonts";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .btn {
    @apply px-3.5 py-2.5 text-lg font-semibold shadow-sm rounded-md no-underline;
  }

  .btn-primary {
    @apply bg-brand-mediumPurple text-white hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-mediumPurple disabled:bg-slate-500 disabled:hover:no-underline disabled:cursor-not-allowed;
  }

  .btn-cta {
    @apply bg-brand-pink text-white hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-pink;
  }

  .btn-link {
    @apply font-semibold;
  }

  .btn-link-inverted {
    @apply text-brand-pink font-semibold;
  }

  label {
    @apply text-slate-900 dark:text-slate-200;
  }

  select[multiple] option:checked {
    @apply bg-brand-mediumPurple text-white;
  }

  select[multiple] option:active {
    @apply bg-brand-mediumPurple text-white;
  }

  select[multiple] option:checked:active {
    @apply bg-brand-mediumPurple text-white;
  }

  .tight-rich-text-block p[data-block-key] {
    line-height: 1.5rem;
  }

  .carousel p[data-block-key] {
    @apply text-slate-900 dark:text-slate-50;
  }

  .hero-section p[data-block-key] {
    @apply text-2xl leading-8 text-slate-500 dark:text-slate-300 font-medium;
  }
}
